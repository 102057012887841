//super-admin routes
export const SUPER_ADMIN_DASHBOARD_PAGE = "/";
export const ADMIN_BUSINESS_DASHBOARD_PAGE = "/business-dashboard";
export const ADMIN_CONTENT_DASHBOARD_PAGE = "/content-dashboard";
export const ADMIN_HR_DASHBOARD_PAGE = "/hr-dashboard";
export const ADMIN_OPERATION_DASHBOARD_PAGE = "/operation-dashboard";
export const ADMIN_QA_DASHBOARD_PAGE = "/qa-dashboard";
export const ADMIN_MIS_DASHBOARD_PAGE = "/mis-dashboard";
export const ADMIN_FINANCE_DASHBOARD_PAGE = "/finance-dashboard";

export const SUPER_ADMIN_MY_PROFILE_PAGE = "/my-profile";
export const SUPER_ADMIN_SETTINGS_PAGE = "/settings";
export const SUPER_ADMIN_MY_ACCOUNT_PAGE = "/settings/my-account";
export const SUPER_ADMIN_MY_SECURITY_PAGE = "/settings/security";
export const SUPER_ADMIN_MY_DEVICE_MANAGEMENT_PAGE =
  "/settings/device-managment";

// Business Development Admin Routes [BDA]
export const SUPER_ADMIN_CLIENT_MANAGEMENT_PAGE = "/client-management";
export const SUPER_ADMIN_CLIENT_MANAGEMENT_PROFILE_PAGE =
  "/client-management/profile";
export const SUPER_ADMIN_CLIENT_MANAGEMENT_BULK_UPLOAD =
  "/client-management/upload-bulk-admin";
export const UPDATE_CLIENT_PROFILE_PAGE = "/client-management/update";
export const BDA_JOB_ROLE_PAGE = "/jobrole-management";
export const BDA_JOB_ROLE_CREATE_PAGE = "/jobrole-management/create";

//User Management Route links
export const SUPER_ADMIN_USER_MANAGEMENT_LIST_PAGE = "/user-management-list";
export const SUPER_ADMIN_USER_MANAGEMENT_CREATE_USER_PAGE = "/create-new-user";
export const SUPER_ADMIN_USER_MANAGEMENT_EDIT_USER_PAGE =
  "/create-new-user/edit-user";
export const SUPER_ADMIN_USER_MANAGEMENT_ROLE_AND_PERMISSION_LIST_PAGE =
  "/role-and-permission-list";
export const SUPER_ADMIN_USER_MANAGEMENT_ROLE_AND_PERMISSION_PAGE =
  "/create-role-and-permissions";
export const SUPER_ADMIN_USER_MANAGEMENT_EDIT_ASSIGNED_PERMISSION_PAGE =
  "/create-role-and-permissions/edit-assigned-permissions";
export const SUPER_ADMIN_USER_MANAGEMENT_EDIT_ROLE_AND_PERMISSION_PAGE =
  "/create-role-and-permissions/edit-role-and-permissions";

export const SUPER_ADMIN_QUESTION_LIST = "/question-list";
export const SUPER_ADMIN_QUESTION_ADD = "/question-list/add";
export const SUPER_ADMIN_BULK_UPLOAD_QUESTION =
  "/question-list/upload-question";
export const SUPER_ADMIN_QUESTION = "/question-list/add/question";
export const SUPER_ADMIN_QUESTION_BANK_PAGE = "/question-bank";
export const SUPER_ADMIN_CREATE_QUESTION_BANK_PAGE = "/create-question-bank";
// export const SUPER_ADMIN_CREATE_QUESTION_BANK_FORM_PAGE =
//   "/create-question-bank/add";
export const SUPER_ADMIN_CREATE_QUESTION_PAGE = "/create-question";
export const SUPER_ADMIN_QUESTION_PREVIEW_PAGE =
  "/question-list/question-preview";
export const SUPER_ADMIN_REPORT_AND_ANALYTICS = "/candidate-result";
export const SUPER_ADMIN_CANDIDATE_RESULT_PREVIEW = "/candidate-result/preview";
export const SUPER_ADMIN_SECTION_EDIT_PAGE = "/question-list/edit-section";
export const SUPER_ADMIN_QUESTION_EDIT_PAGE =
  "/question-list/question-preview/edit";

//question Bank new flow

export const SUPER_ADMIN_QUESTION_FORM_LIST_PAGE = "/question-form-list";
export const SUPER_ADMIN_CREATE_QUESTION_FORM_PAGE = "/create-question-form";
export const SUPER_ADMIN_EDIT_QUESTION_FORM_PAGE = "/edit-question-form";
export const SUPER_ADMIN_VIEW_QUESTIONS_PAGE = "/questions";
export const SUPER_ADMIN_EDIT_QUESTION_VIVA_PAGE = "/questions/edit";

//Assessment Route links
export const SUPER_ADMIN_ASSESSMENT_LIST_PAGE = "/assessment";
export const SUPER_ADMIN_CREATE_ASSESSMENT_PAGE = "/assessment/create";
export const SUPER_ADMIN_ASSESSMENT_ADD_QUESTIONS_PAGE =
  "/assessment/add-questions";
export const SUPER_ADMIN_ASSESSMENT_PREVIEW_PAGE = "/assessment/preview";

//Exam management Route links
export const SUPER_ADMIN_EXAM_MANAGEMENT_PAGE = "/exam-management";
export const SUPER_ADMIN_EXAM_MANAGEMENT_CANDIDATE_LIST_PAGE =
  "/exam-management/candidate-list";
export const SUPER_ADMIN_EXAM_MANAGEMENT_EDIT_CANDIDATE_FORM_PAGE =
  "/exam-management/edit-candidate-form";
//exam centre
export const SUPER_ADMIN_EXAM_MANAGEMENT_LIST_PAGE =
  "/exam-management/exam-center";
export const SUPER_ADMIN_CREATE_EXAM_CENTER_PAGE =
  "/exam-management/create-exam-center";
export const SUPER_ADMIN_EDIT_EXAM_CENTER_PAGE =
  "/exam-management/edit-exam-center";
//Batch
export const SUPER_ADMIN_BATCH_LIST_PAGE = "/exam-management/batch-list";
export const SUPER_ADMIN_BATCH_MANAGEMNET_LIST_PAGE =
  "/exam-management/batch-list";
export const SUPER_ADMIN_CREATE_BATCH_PAGE = "/exam-management/create-batch";
export const SUPER_ADMIN_NEW_BATCH_LIST_PAGE =
  "/exam-management/new/batch-list";
export const SUPER_ADMIN_CREATE_NEW_BATCH_PAGE =
  "/exam-management/create-batch";
export const SUPER_ADMIN_EDIT_BATCH_PAGE = "/exam-management/edit-batch";
export const SUPER_ADMIN_ASSESSMENT_STATS = "/exam-management/assessment-stats";
export const SUPER_ADMIN_LIVE_MONITORING_PAGE =
  "/exam-management/live-monitoring";
export const SUPER_ADMIN_LIVE_MONITORING__CANDIDATE_PAGE =
  "/exam-management/live-monitoring-candidate";
//assign batch
export const SUPER_ADMIN_ASSIGN_BATCH = "/exam-management/assign-batch";

// Demo user leads management
export const SUPER_ADMIN_USER_DEMO_LEAD_MANAGEMENT = "/leads-management-list";

// Scheme Management

export const SUPER_ADMIN_SCHEME_MANAGEMENT = "/scheme-management-list";
export const SUPER_ADMIN_SCHEME_MANAGEMENT_CREATE_SCHEME = "/create-scheme";
export const SUPER_ADMIN_SCHEME_MANAGEMENT_EDIT_SCHEME = "/edit-scheme";

// sub scheme management
export const SUPER_ADMIN_SUB_SCHEME_MANAGEMENT = "/sub-scheme-management-list";
export const SUPER_ADMIN_SUB_SCHEME_MANAGEMENT_CREATE_SCHEME =
  "/create-sub-scheme";
export const SUPER_ADMIN_SUB_SCHEME_MANAGEMENT_EDIT_SUB_SCHEME =
  "/edit-sub-scheme";

// All Batches
export const SUPER_ADMIN_ALL_BATCHES = "/all-batches";
export const SUPER_ADMIN_ALL_BATCHES_REQUEST = "/all-batches/batch-request";
export const SUPER_ADMIN_ASSESSMENT_BATCH = "/all-batches/assessment-batch";

//Instructions
export const CREATE_INSTRUCTIONS = "/create-instructions";
export const INSTRUCTIONS_LIST = "/instructions-list";
export const EDIT_INSTRUCTIONS = "/edit-instructions";

// Assessor Management
export const ASSESSOR_MANAGEMENT_HOME = "/assessors";
export const ASSESSOR_MANAGEMENT_ADD_NEW = "/assessors/add";
export const ASSESSOR_MANAGEMENT_ADD_BULK_UPLOAD = "/assessors/bulk-upload";
export const ASSESSOR_MANAGEMENT_UPDATE = "/assessors/update";

// NOS Management
export const QUESTION_BANK_NOS = "/question-bank/nos";
export const QUESTION_BANK_NOS_UPLOAD = "/nos/upload";
export const QUESTION_BANK_NOS_TABLE = "/nos/table";

// QA Verification Tab

export const VERIFICATION_TAB_LIST_PAGE = "/verification-tab";
export const UPLOAD_DOCUMENTS_PAGE = "/upload-documents";

// Results Tab
export const ONLINE_RESULTS_TAB_PAGE = "/online-results";
export const OFFLINE_RESULTS_TAB_PAGE = "/offline-result";
export const CANDIDATE_RESULTS_PAGE = "/online-results/results";
export const UPLOAD_OFFLINE_RESULTS = "/upload-result";
export const UPLOAD_OFFLINE_ANSWER_KEYS = "/upload-answer-keys";
export const NOS_WISE_RESULTS_PAGE = "/online-results/nos-results";
export const NOS_WISE_RESULTS_MAIN_TABLE = "/online-results/nos-table";
export const NOS_WISE_OFFLINE_PAGE = "/offline-results/view";
export const NOS_WISE_OFFLINE_RESULTS_MAIN_TABLE = "/offline-results/nos-table";
export const OFFLINE_RESULTS_STATS = "/offline-results/results-stats";
export const UPDATE_CANDIDATE_RESULT_PAGE = "/online-results/results/update";

// Proctor Management
export const PROCTOR_MANAGEMENT_HOME = "/proctors";
export const PROCTOR_MANAGEMENT_ADD_NEW = "/proctors/add";
export const PROCTOR_MANAGEMENT_UPDATE = "/proctors/update";
export const PROCTOR_MANAGEMENT_BULK_UPLOAD = "/proctors/bulk-upload";

// Logs Management Pages
export const LOGS_MANAGEMENT_ACIVITY_LOGS_BATCHS_LIST_PATH =
  "/logs/activity-batches";
export const LOGS_MANAGEMENT_ACTIVITY_LOGS_CANDIDATE_BY_BATCH_LIST_PATH =
  "/logs/activity-candidate-by-batch";
export const LOGS_MANAGEMENT_PROCTOR_ACTIVITY_LOGS_BATCHS_LIST_PATH =
  "/logs/proctor-activity-batches";
export const LOGS_MANAGEMENT_PROCTOR_ACTIVITY_LOGS_CANDIDATE_BY_BATCH_LIST_PATH =
  "/logs/proctor-activity-candidate-by-batch";
export const LOGS_MANAGEMENT_PROCTOR_IMAGE_BY_CANDIDATE_PATH =
  "/logs/proctor-by-candidate-image";
export const LOGS_MANAGEMENT_PROCTOR_VIDEO_BY_CANDIDATE_PATH =
  "/logs/proctor-by-candidate-video";
export const LOGS_MANAGEMENT_ACIVITY_LOGS_BATCHS_LIST =
  "/logs/activity-batches";

// new path for protoring

export const LOGS_MANAGEMENT_PROCTOR_THEORY_BY_CANDIDATE_PATH =
  "/logs/proctor-by-candidate-by-section";
export const LOGS_MANAGEMENT_PROCTOR_PRACTICAL_BY_CANDIDATE_PATH =
  "/logs/proctor-by-candidate-practical";
export const LOGS_MANAGEMENT_PROCTOR_VIVA_BY_CANDIDATE_PATH =
  "/logs/proctor-by-candidate-viva";

// Assessor Attendance

export const ASSESSOR_ATTENDANCE_LIST = "/assessor-attendance-list";
export const ASSESSOR_ATTENDANCE_REGULARIZE_PAGE = "/attendance-regularize";
export const SINGLE_ASSESSOR_ATTENDANCE_PAGE = "/attendance-log";

// NEW
export const ASSESSOR_MANAGEMENT_VIEW = "/assessors/view";

import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    clientLists: [],
    clientStatistics: {},
    clientSummaryList: [],
    widgetStats: [],
    batchStatistics: {},
    dashboardsData: {},
    overViewTableData: [],
  },
  reducers: {
    getClientLists: (state, { payload }) => {
      state.clientLists = payload;
    },
    getClientStatistics: (state, { payload }) => {
      state.clientStatistics = payload;
    },
    getClientSummaryList: (state, { payload }) => {
      state.clientSummaryList = payload;
    },
    getWidgetStats: (state, { payload }) => {
      state.widgetStats = payload;
    },
    getBatchStatistics: (state, { payload }) => {
      state.batchStatistics = payload;
    },
    getDashboardsData: (state, { payload }) => {
      state.dashboardsData = payload;
    },
    getOveriewTableData: (state, { payload }) => {
      state.overViewTableData = payload;
    },
  },
});

export const {
  getClientLists,
  getClientStatistics,
  getClientSummaryList,
  getWidgetStats,
  getBatchStatistics,
  getDashboardsData,
  getOveriewTableData,
} = dashboardSlice.actions;

export const dashboardSelector = (state) => state.admin.dashboard;
const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
